body {
  margin: 0;
  padding: 0;
  font-family: Roboto, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --filter-panel-width: 348px;

  --shows-section-color: #383A4C;
  --shows-card-color: #424457;
  --filters-modal-color: #2C2F3A;
  --app-header-color: #252841;
  --footer-color: #252841;
  --border-color: #5A5A7C;
  --show-card-border-color: #9292B0;
  --mal-color: #2E51A2;
  --accent-color: #94ABE0;
  --icon-hover-bg-color: #474B76;
  --btn-color: #474B76;
  --btn-hover-color: #505C86;
}

::-webkit-scrollbar {
  width: 6px;
  background: #1C2021;
}

::-webkit-scrollbar-thumb {
  background-color: #7A7A9F;
  border-radius: 6px;
  width: 50%;
}

.icon-hover {
  border-radius: 4px;
  &:hover {
    background-color: var(--icon-hover-bg-color);
  }
}
