:root {
  --range-handle-size: 16px;
}

.slider-filter_header {
  display: flex;
  align-items: baseline;
}

.slider-filter_header_reset {
  font-size: 11px;
  color: lightgray;
  cursor: pointer;
  margin-left: 12px;
}

.slider-filter_wrapper {
  position: relative;
  display: flex;
  align-items: center;
  height: 30px;
  width: 264px;
  margin-bottom: 24px;
  padding-left: 8px;
}

.slider-filter_inputs_wrapper {
  position: absolute;
  height: var(--range-handle-size);
  /* width - extra half of handle width at each end */
  width: calc(100% + var(--range-handle-size));
  /* shift wrapper by half of handle width */
  margin: 0 calc(var(--range-handle-size) / -2);
}

.slider-filter_input {
  /* disable clicks on range inputs to prevent clicks to their tracks */
  pointer-events: none;
  position: absolute;
  width: 100%;
  height: 100%;
  margin: 0;
  z-index: 5;
  appearance: none;
  -webkit-appearance: none;
  background-color: transparent;
}

.slider-filter_input::-webkit-slider-thumb {
  /* enable clicks only on the handles of each range input */
  pointer-events: all;
  appearance: none;
  -webkit-appearance: none;
  width: var(--range-handle-size);
  height: var(--range-handle-size);
  border-radius: 50%;
  cursor: grab;
}

.slider-filter_input::-moz-range-thumb {
  /* enable clicks only on the handles of each range input */
  pointer-events: all;
  appearance: none;
  -moz-appearance: none;
  border: none;
  background-color: transparent;
  width: var(--range-handle-size);
  height: var(--range-handle-size);
  border-radius: 50%;
  cursor: grab;
}

.slider-filter_input::-ms-thumb {
  /* enable clicks only on the handles of each range input */
  pointer-events: all;
  appearance: none;
  -webkit-appearance: none;
  width: var(--range-handle-size);
  height: var(--range-handle-size);
  border-radius: 50%;
  cursor: grab;
}

.slider-filter_track_wrapper {
  position: absolute;
  display: flex;
  align-items: center;
  height: var(--range-handle-size);
  width: 100%;
}

.slider-filter_track {
  position: absolute;
  width: 100%;
  height: 6px;
  border-radius: 4px;
  background-color: var(--border-color);
}

.slider-filter_track_inner {
  position: absolute;
  height: 100%;
  border-radius: 4px;
  background-color: var(--accent-color);
}

.slider-filter_track_handle {
  /* disable pointer events so clicks go to underling range input handle */
  pointer-events: none;
  position: absolute;
  height: var(--range-handle-size);
  width: var(--range-handle-size);
  box-sizing: border-box;
  margin-left: calc(var(--range-handle-size) / -2);
  z-index: 5;
  background-color: var(--accent-color);
  border-radius: 50%;
  border: 1px solid var(--border-color);
}

.slider-filter_track_handle-value {
  position: absolute;
  top: 20px;
  color: white;
  font-size: 11px;
  cursor: default;
}
