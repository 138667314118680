.show-card_wrapper {
  width: 80%;
  max-width: 800px;
  min-width: 300px;
  margin: 12px 12px 24px;
  padding: 24px 24px 0;
  box-sizing: border-box;
  border-radius: 4px;
  border: 2px solid var(--show-card-border-color);
  background-color: var(--shows-card-color);
}

.show-card_details-row {
  display: flex;
}

.show-card_details-row_image-wrapper {
  position: relative;
  max-width: 120px;
  min-width: 120px;
  overflow: hidden;
}

.show-card_details-row_image-hidden {
  visibility: hidden;
  max-width: 0;
  min-width: 0;
}

.show-card_details-row_image {
  display: block;
  max-height: 100%;
  max-width: 100%;
  object-fit: contain;
}

.show-card_details-row_image-tooltip {
  position: absolute;
  bottom: -24px;
  display: flex;
  justify-content: center;
  height: 24px;
  width: 100%;
  padding: 4px;
  font-size: 12px;
  line-height: 16px;
  box-sizing: border-box;
  background-color: rgba(51, 51, 51, 0.5);
  backdrop-filter: blur(4px);
  transition: bottom 0.2s ease-in-out;
}

.show-card_details-row_image-wrapper:hover .show-card_details-row_image-tooltip {
  bottom: 0;
}

.show-card_details-row_info {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  margin-left: 24px;
}

.show-card_details-row_info_title-stats {
  display: flex;
}

.show-card_details-row_info_title-wrapper {
  flex-grow: 1;
  margin-bottom: 8px;
  overflow-wrap: anywhere;
}

.show-card_details-row_info_default-title {
  font-size: 20px;
}

.show-card_details-row_info_english-title {
  margin-top: 4px;
  font-size: 16px;
  font-style: italic;
}

.show-card_details-row_info-item {
  margin-top: 8px;
  font-size: 14px;
  white-space: pre;
}

.show-card_details-row_mal-metrics {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 64px;
  max-width: 124px;
  margin: -24px -24px 0 12px;
  padding: 12px;
  box-sizing: border-box;
  border-radius: 0 3px;
  background-color: var(--mal-color);
  text-align: center;
}

.show-card_details-row_mal-metrics_score {
  margin-bottom: 4px;
  font-size: 20px;
  white-space: pre;
}

.show-card_details-row_mal-metrics_users {
  font-size: 11px;
  white-space: pre;
}

.show-card_details-row_tags {
  display: flex;
  flex-wrap: wrap;
  margin-top: 8px;
}

.show-card_details-row_tags-tag {
  padding: 4px 8px;
  margin: 9px 8px 0 0;
  font-size: 12px;
  border-radius: 4px;
  white-space: pre;
}

.show-card_synopsis {
  margin-top: 24px;
  overflow: hidden;
  font-size: 15px;
}

.show-card_synopsis_toggle {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 0;
  border-top: 1px solid var(--show-card-border-color);
  font-size: 14px;
  &:hover {
    cursor: pointer;
  }
}

.show-card_synopsis_toggle:hover .show-card_synopsis_toggle_icon-open {
  animation: bounceDown 1.2s ease-in infinite;
  animation-delay: 0.3s;
}

.show-card_synopsis_toggle:hover .show-card_synopsis_toggle_icon-close {
  animation: bounceUp 1.2s ease-in infinite;
  animation-delay: 0.3s;
}

.show-card_synopsis_toggle_label {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 24px;
}

.show-card_synopsis_toggle_icon {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.show-card_synopsis_toggle_icon > svg {
  height: 20px;
  width: 20px;
}

.show-card_synopsis_content-wrapper {
  display: flex;
  justify-content: center;
  max-height: 0px;
  line-height: 1.5;
  box-sizing: border-box;
  /* closing transition */
  transition: max-height 0.3s cubic-bezier(0, 1, 0, 1);
}

.show-card_synopsis_content {
  max-width: 600px;
}

.show_card_synopsis_expanded {
  max-height: 1000px;
  margin-bottom: 24px;
  /* opening transition */
  transition: max-height 0.3s cubic-bezier(1, 0.5, 1, 0);
}

@keyframes bounceDown {
  0% {
    transform: translate(0, 0);
  }
  15% {
    transform: translate(0, 1.5px);
  }
  30% {
    transform: translate(0, 0px);
  }
  45% {
    transform: translate(0, 1.5px);
  }
  60% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(0, 0);
  }
}

@keyframes bounceUp {
  0% {
    transform: translate(0, 0);
  }
  15% {
    transform: translate(0, -1.5px);
  }
  30% {
    transform: translate(0, 0px);
  }
  45% {
    transform: translate(0, -1.5px);
  }
  60% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(0, 0);
  }
}