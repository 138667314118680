.pagination-footer_wrapper {
  display: flex;
  align-items: center;
  height: 44px;
  padding:  12px 12px 12px 24px;
  box-sizing: border-box;
  background-color: var(--footer-color);
  border-top: 1px solid var(--border-color);
  color: white;
  z-index: 10;
}

.pagination-footer_arrow {
  display: flex;
  align-items: center;
  padding: 6px 8px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s;
  &:hover {
    background-color: var(--icon-hover-bg-color);
  }
}

.pagination-footer_arrow_right {
  margin: 0 24px 0 4px;
}

.pagination-footer_input {
  width: 48px;
  margin: 0 4px;
}

.pagination-footer_page-btn {
  margin-left: 8px;
  padding: 6px 10px;
  color: white;
  background-color: var(--btn-color);
  border: none;
  border-radius: 4px;
  &:hover {
    cursor: pointer;
    background-color: var(--btn-hover-color);
  };
}