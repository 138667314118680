.app-wrapper {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.app-content {
  position: relative;
  display: flex;
  height: calc(100vh - 52px);
  width: 100vw;
}

.shows-footer-wrapper {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  background-color: var(--shows-section-color);
  overflow-x: hidden;
}

.show-section {
  position: relative;
  height: calc(100vh - 52px - 44px);
  width: 100%;
  box-sizing: border-box;
  color: white;
}

.show-list-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: calc(100vh - 52px - 36px - 44px);
  padding: 24px 24px 0;
  box-sizing: border-box;
  overflow: auto;
}

.show-section_entry-count {
  display: flex;
  align-items: center;
  height: 36px;
  width: 100%;
  padding: 8px 24px 8px 40px;
  box-sizing: border-box;
  font-size: 13px;
  border-bottom: 1px solid var(--border-color);
  z-index: 5;
}

.show-section_loading {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
}
