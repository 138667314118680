.FiltersPanel {
  position: relative;
  height: calc(100vh - 52px);
  min-width: var(--filter-panel-width);
  max-width: var(--filter-panel-width);
  box-sizing: border-box;
  border-right: 1px solid var(--border-color);
  background-color: var(--filters-modal-color);
  overflow-x: hidden;
}

.FiltersPanel_closed {
  min-width: 0;
  max-width: 0;
}

.filters-panel_close-btn {
  position: absolute;
  top: 8px;
  left: calc(var(--filter-panel-width) - 20px);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 36px;
  width: 36px;
  background-color: var(--btn-color);
  border: 1px solid var(--border-color);
  border-radius: 50%;
  z-index: 5;
  transition: background-color 0.2s;
  &:hover {
    cursor: pointer;
    background-color: var(--btn-hover-color);
  }
}

.filters-panel_close-btn_closed {
  left: -16px;
  &:hover {
    animation: bounceRight 1.2s ease-in infinite;
}
}

.filter-panel_toggle-icon svg {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 16px;
  width: 16px;
  fill: white;
  fill: lightgrey;
}

.filters-panel_filters-wrapper {
  height: calc(100vh - 52px - 56px);
  padding: 12px 24px 24px;
  box-sizing: border-box;
  overflow-y: scroll;
  overflow-x: hidden;
}

.filters-panel-buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 56px;
  min-width: var(--filter-panel-width);
  max-width: var(--filter-panel-width);
  padding: 1px 24px 0;
  box-sizing: border-box;
  border-bottom: 1px solid var(--border-color);
}

.filter-panel_submit-btn {
  padding: 8px 12px;
  font-size: 14px;
  color: white;
  background-color: var(--btn-color);
  border: none;
  border-radius: 4px;
  transition: background-color 0.2s;
  white-space: pre;
  &:hover {
    cursor: pointer;
    background-color: var(--btn-hover-color);
  }
}

.filters-panel-buttons_reset {
  font-size: 11px;
  color: lightgray;
  cursor: pointer;
  margin: 0 8px 0 12px;
  padding: 4px 8px;
  border-radius: 4px;
  transition: background-color 0.2s;
  white-space: pre;
  &:hover {
    background-color: var(--btn-color);
  }
}

.multiselect-filter_header {
  display: flex;
  align-items: center;
}

.multiselect-filter_header_tag {
  margin-left: 16px;
  height: 12px;
  min-width: 20px;
  max-width: 20px;
  border-radius: 4px;
}

.filter_title {
  margin: 8px 0px 8px 0px;
  color: white;
  cursor: default;
  font-size: 14px;
  white-space: pre;
}

.multiselect-filter-wrapper {
  padding-bottom: 12px;
}

.multiselect-filter_columns {
  display: flex;
  align-items: flex-start;
}

.multiselect-filter_column {
  min-width: 120px;
}

.multiselect-filter_column_left {
  padding-right: 18px;
}

.filter-checkbox-wrapper {
  display: flex;
  align-items: center;
  width: fit-content;
  &:hover {
    cursor: pointer;
  }
}

.filter-checkbox_disabled {
  &:hover {
    cursor: not-allowed;
  }
}

.filter-checkbox-input {
  margin-right: 0;
  &:hover {
    cursor: inherit;
  }
}

.filter-checkbox-label {
  padding: 0 4px 0 12px;
  font-size: 12px;
  color: white;
  white-space: pre;
  &:hover {
    cursor: inherit;
  }
}

.filter-clause-tag {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: fit-content;
  border-radius: 4px;
  font-size: 12px;
  margin: 4px 0 4px;
}

.filter-clause-tag_content {
  padding: 6px 0 6px 8px;
}

.filter-clause-tag_remove {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 8px;
  width: 8px;
  padding: 6px;
  margin: 0 4px;
  &:hover {
    cursor: pointer;
  }
}

.multiselect-filter_clause-btn {
  margin: 4px 0 8px 0;
  padding: 4px 8px;
  color: white;
  background-color: var(--btn-color);
  border: none;
  border-radius: 4px;
  transition: background-color 0.2s;
  white-space: pre;
  &:hover {
    cursor: pointer;
    background-color: var(--btn-hover-color);
  }
}

.multiselect-filter_clause-btn:disabled {
  color: lightgrey;
  background-color: #4F4F4F;
  &:hover {
    cursor:not-allowed;
    background-color: #4F4F4F;
  }
}

@keyframes bounceRight {
  0% {
    transform: translate(0, 0);
  }
  15% {
    transform: translate(4px, 0);
  }
  30% {
    transform: translate(0, 0px);
  }
  45% {
    transform: translate(4px, 0);
  }
  60% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(0, 0);
  }
}